import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import CancelIcon from "@mui/icons-material/Cancel";
import { useCountryContext } from "../Public";

interface LanguageSettingsDialogProps {
  countries: any;
  selectedCountry: string;
  selectedLanguage: string;
  selectedCurrency: string;
  setSelectedCountry: (country: string) => void;
  setSelectedLanguage: (language: string) => void;
  setSelectedCurrency: (currency: string) => void;
}

const LanguageSettingsDialog: React.FC<LanguageSettingsDialogProps> = ({
  countries,
  selectedCountry,
  selectedLanguage,
  selectedCurrency,
  setSelectedCountry,
  setSelectedLanguage,
  setSelectedCurrency,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [tempCountry, setTempCountry] = useState<string>("");
  const [tempLanguage, setTempLanguage] = useState<string>("");
  const [tempCurrency, setTempCurrency] = useState<string>("");
  const [availableLanguages, setAvailableLanguages] = useState<any[]>([]); // Holds the languages of the selected country
  const [currencyABN, setAurrencyABN] = useState();
  const [langicon, setLangicon] = useState();

  const { currentCountry, handleCurrentCountry } = useCountryContext();

  const handleOpen = () => {
    setTempCountry(selectedCountry);
    setTempLanguage(selectedLanguage);
    setTempCurrency(selectedCurrency);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Fetch geolocation data
  useEffect(() => {
    const fetchGeoLocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        const userCountry = data.country_name;

        const country = countries.find((item: any) => item.name === userCountry);
        if (country) {
          setSelectedCountry(country.name);
          setAvailableLanguages(country.languages);
          setSelectedLanguage(country.languages[0]?.name || "");
          setSelectedCurrency(country.currencyIcon);
          setAurrencyABN(country.currencyABN);
          setLangicon(country.icon);
        } else {
          let countryJson = {
            code: "us",
            currencyABN: "USD",
            currencyIcon: "$",
            icon: "USA",
            name: "United States"
          }
          handleCurrentCountry(countryJson);
        }
      } catch (error) {
        console.error("Error fetching geolocation:", error);
      }
    };

    if (countries.length > 0) {
      fetchGeoLocation();
    }
  }, [
    countries,
    setSelectedCountry,
    setSelectedLanguage,
    setSelectedCurrency,
  ]);

  // Handle country selection
  const handleCountryChange = (event: any) => {
    const countryName = event.target.value;
    setTempCountry(countryName);

    const country = countries.find((item: any) => item.name === countryName);
    if (country) {
      setAvailableLanguages(country.languages);
      setTempLanguage(country.languages[0]?.name || "");
      setTempCurrency(country.currencyIcon);
      setAurrencyABN(country.currencyABN);
      setLangicon(country.icon);
    }
  };

  // Handle language selection
  const handleLanguageChange = (event: any) => {
    setTempLanguage(event.target.value);
  };

  // Handle currency selection
  const handleCurrencyChange = (event: any) => {
    setTempCurrency(event.target.value);
  };

  // Handle form submission
  const handleSubmit = () => {
    setSelectedCountry(tempCountry);
    setSelectedLanguage(tempLanguage);
    setSelectedCurrency(tempCurrency);
    const country = countries.find((item: any) => item.name === tempCountry);
    let countryJson = {
      code: country.code,
      currencyABN: country.currencyABN,
      currencyIcon: country.currencyIcon,
      icon: country.icon,
      name: country.name
    }
    handleCurrentCountry(countryJson);
    handleClose();
  };

  return (
    <Box
      sx={{
        background: "#c58b9324",
        padding: "5px",
        borderRadius: "5px",
      }}
    >
      <Button
        sx={{
          backgroundColor: "transparent",
          color: "#000",
          fontSize: "15px",
          fontWeight: "400",
          border: "none",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          padding: "2px",
          minWidth: 0,
          textTransform: "lowercase",
        }}
        onClick={handleOpen}
      >
        {selectedCountry && selectedLanguage && selectedCurrency ? (
          <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <PublicIcon sx={{ verticalAlign: "middle", fontSize: { xs: "20px", lg: "15px" } }} />
            <Typography variant="body1" sx={{ fontSize: { xs: "20px", lg: "15px" } }}>{selectedCountry}</Typography>
            <Divider orientation="vertical" variant="middle" flexItem />{" "}
            <GTranslateIcon
              sx={{ verticalAlign: "middle", fontSize: { xs: "20px", lg: "15px" } }}
            />
            <Typography variant="body1" sx={{ fontSize: { xs: "20px", lg: "15px" } }} > {selectedLanguage}</Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Typography variant="body1" sx={{ fontSize: { xs: "20px", lg: "15px" } }} >{selectedCurrency}</Typography>
          </Box>
        ) : (
          "Country | Language | Currency"
        )}
      </Button>

      <Dialog
        open={open}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            width: {
              xs: "100%",
              sm: "80%",
              md: "40%",
              lg: "40%",
            },
          },
        }}
        onClose={handleClose}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between"}}>
          <DialogTitle>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.3rem",
                  lg: "1.3rem",
                },
                fontWeight: "bolder",
              }}
            >
              Update Your Language Settings
            </Typography>
          </DialogTitle>

          <DialogActions sx={{ cursor: "pointer" }}>
            <CancelIcon onClick={handleClose} />
          </DialogActions>
        </Box>
        <Divider />

        <Typography
          variant="body2"
          sx={{
            padding: "10px 0px 0px 10px",
            fontSize: "1rem",
            paddingLeft: "10px",
            fontWeight: "600",
          }}
        >
          Set where you live and what language you speak and the currency{" "}
        </Typography>

        <DialogContent
          sx={{ "&.MuiDialogContent-root": { padding: "0px  30px" } }}
        >
          <FormControl fullWidth margin="normal">
            <Typography
              variant="body1"
              sx={{ fontSize: "1rem", fontWeight: "bolder" }}
            >
              Select country
            </Typography>
            <Select
              value={tempCountry}
              onChange={handleCountryChange}
              displayEmpty
              sx={{
                height: "50px",
                borderRadius: "15px", // Border-radius for the Select component
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Default border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Border color when focused
                },
              }}
            >
              <MenuItem value="">
                <em>Select a country</em>
              </MenuItem>
              {countries.map((item: any) => (
                <MenuItem key={item.code} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Typography
              variant="body1"
              sx={{ fontSize: "1rem", fontWeight: "bolder" }}
            >
              Select Language
            </Typography>
            <Select
              value={tempLanguage || ""}
              onChange={handleLanguageChange}
              displayEmpty
              sx={{
                height: "50px",
                borderRadius: "15px", // Border-radius for the Select component
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Default border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Border color when focused
                },
              }}
            >
              <MenuItem value="">
                <em>Select a language</em>
              </MenuItem>
              {availableLanguages.map((lang) => (
                <MenuItem key={lang.code} value={lang.name}>
                  {lang.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Typography
              variant="body1"
              sx={{ fontSize: "1rem", fontWeight: "bolder" }}
            >
              Your Currency
            </Typography>

            <Select
              value={tempCurrency || ""}
              onChange={handleCurrencyChange}
              displayEmpty
              sx={{
                height: "50px",
                borderRadius: "15px", // Border-radius for the Select component
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Default border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Border color when focused
                },
              }}
              disabled
            >
              <MenuItem value="">
                <em>Select a currency symbol</em>
              </MenuItem>
              {tempCurrency && currencyABN && langicon && (
                <MenuItem value={tempCurrency}>
                  {tempCurrency} {langicon} ({currencyABN}){" "}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-right",
            alignItems: "center",
            paddingRight:"40px"
          }}
        >
          <Button
            sx={{
              borderRadius:"45px",
              backgroundColor: "black", // Default background color
              color: "white", // Text color
              "&:hover": {
                backgroundColor: "gray", // Background color on hover
              },
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button
            sx={{
              borderRadius:"45px",
              backgroundColor: "black", // Default background color
              color: "white", // Text color
              "&:hover": {
                backgroundColor: "gray", // Background color on hover
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LanguageSettingsDialog;

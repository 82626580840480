import React, { createContext, useContext, useState, ReactNode } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Define the context type
interface EmailSnackbarContextType {
  showSnackbar: (message: string, canClose?: boolean) => void;
}

// Create the context
const EmailSnackbarContext = createContext<EmailSnackbarContextType | undefined>(undefined);







export const useEmailSnackbarContext = () => {
  const context = useContext(EmailSnackbarContext);
  if (!context) {
    throw new Error('useEmailSnackbarContext must be used within a EmailSnackbarProvider');
  }
  return context;
};

interface EmailSnackbarProviderProps {
  children: ReactNode;
}








export const EmailSnackbarProvider: React.FC<EmailSnackbarProviderProps> = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarCanClose, setSnackbarCanClose] = useState(true);

  const showSnackbar = (message: string,canClose:boolean=true) => {
    setSnackbarMessage(message);
    setSnackbarCanClose(canClose);
    setSnackbarOpen(true);
  };

  const handleSnackBarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <EmailSnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{vertical:"top",horizontal:"center"}}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </EmailSnackbarContext.Provider>
  );
};

export default EmailSnackbarProvider;

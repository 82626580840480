import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "i18next";
import "./App.css";
import { Box, CircularProgress } from "@mui/material";
import Public from "./public/Public";
import Splash from "./layout/splashScreen/Splash";
import AllTicketsAndStatus from "./ticket/AllTicketsAndStatus";
import SupportChat from "./ticket/SupportChat";
import ScrollManager from "./public/pageScrollTop/ScrollTop";
import Content from "./public/help/Content";
import JewelryBase from "./ExploreFunctionality/JewelryBase";
import Jewelry from "./ExploreFunctionality/Jewelry";

const Home = lazy(() => import("./public/home/Home"));
const ProductList = lazy(() => import("./public/products/ProductList/ProductList"));
const ProductDetail = lazy(() => import("./public/products/productDetail/ProductDetail"));
const SignIn = lazy(() => import("./session/SignIn"));
const SignUp = lazy(() => import("./session/SignUp"));
const OrderDetails = lazy(() => import("./public/order/OrderDetails"));
const OrderList = lazy(() => import("./public/order/OrderList"));
const Blog = lazy(() => import("./public/blog/Blog"));
const BlogDetails = lazy(() => import("./public/blog/BlogDetails"));
const Help = lazy(() => import("./public/help/Help"));
const NoPage = lazy(() => import("./layout/NoPage"));
const AboutUs = lazy(() => import("./public/about/AboutUs"));
const ContactUs = lazy(() => import("./public/help/ContactUs"));
const AllFaqs = lazy(() => import("./public/help/faq/AllFaqs"));
const Profile = lazy(()=>import("./layout/userprofile/Profile"));

export function CircularIndeterminate() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0, 0.7)",
        zIndex: 9999,
      }}
    >
      <CircularProgress sx={{ color: "var(--light-color)" }} />
    </Box>
  );
}

type SignInStatus = 'verified' | 'failed';
interface SignInProps {
  status?: SignInStatus;
}

const Signinroutes: { path: string; props: Partial<SignInProps> }[] = [
  { path: 'signin', props: {} },
  { path: 'signin/email/verified', props: { status: 'verified' } },
  { path: 'signin/email/fail', props: { status: 'failed' } },
];

function App() {
  const { t } = useTranslation();
  return (
    <>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
        <ScrollManager />

          <Routes>
            <Route path="/" element={<Public />}>
              <Route
                index
                element={
                  <Suspense fallback={<Splash />}>
                    <Home />
                  </Suspense>
                }
              />
              <Route
                path="products/:categoryKey?/:styleKey?/:subCategoryKey?"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <ProductList />
                  </Suspense>
                }
              />
              <Route
                path="product/:abbrevation"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <ProductDetail />
                  </Suspense>
                }
              />
              <Route
                path="orders"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <OrderList />
                  </Suspense>
                }
              />
              <Route
                path="orders/:orderNumber"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <OrderDetails />
                  </Suspense>
                }
              />
              <Route
                path="orders/:type/success/:session_id"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <OrderList />
                  </Suspense>
                }
              />
              {/* <Route
                path="signin"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <SignIn />
                  </Suspense>
                }
              />
              <Route
                path="signin/email/verified"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <SignIn />
                  </Suspense>
                }
              />
              <Route
                path="signin/email/fail"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <SignIn />
                  </Suspense>
                }
              /> */}
                {Signinroutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <Suspense fallback={<CircularIndeterminate />}>
                      <SignIn  {...route.props}/>
                    </Suspense>
                  }
                />
              ))}
              <Route
                path="signup"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <SignUp />
                  </Suspense>
                }
              />
              <Route
                path="blog"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Blog />
                  </Suspense>
                }
              />
              <Route
                path="blog/:blogName"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <BlogDetails />
                  </Suspense>
                }
              />
              {/* Help section */}
              <Route
                path="help"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Help name="help" />
                  </Suspense>
                }
              />
              <Route
                path="help/shipping"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Help name="shipping" />
                  </Suspense>
                }
              />
              <Route
                path="help/return-and-exchange"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Help name="return-and-exchange" />
                  </Suspense>
                }
              />
              <Route
                path="help/warrenty"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Help name="warrenty" />
                  </Suspense>
                }
              />
              <Route
                path="help/all-faqs"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <AllFaqs />
                  </Suspense>
                }
              />
              <Route
                path="help/contact-us"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <ContactUs />
                  </Suspense>
                }
              />
              <Route
                path="terms-and-conditions"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Content name="terms-and-conditions" />
                  </Suspense>
                }
              />
              <Route
                path="privacy-policy"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Content name="privacy-policy" />
                  </Suspense>
                }
              />
              <Route
                path="about-us"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <AboutUs />
                  </Suspense>
                }
              />
              <Route
                path="contact-us"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <ContactUs />
                  </Suspense>
                }
              />


              <Route
                path="ticket"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <AllTicketsAndStatus />
                  </Suspense>
                }
              />

              <Route
                path="support-chat"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <SupportChat />
                  </Suspense>
                }
              />

              <Route
                path="/profile"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Profile />
                  </Suspense>
                }
              />



            </Route>

            <Route element={<JewelryBase />}>
              <Route
                index
                path="jewelry/:id"
                element={
                    <Jewelry />
                }
              />
            </Route>

            <Route path="*" element={<NoPage />} />
          </Routes>
        </I18nextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;

// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// const ScrollTop = () => {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);
//   return null;
// };

// export default ScrollTop;





import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollTop: React.FC = () => {
  const { pathname } = useLocation();
  const [scrollPositions, setScrollPositions] = useState<Record<string, number>>({});
  
  const HEADER_HEIGHT = 100; // Replace with actual header height
  const FOOTER_HEIGHT = 100; // Replace with actual footer height
  const OFFSET = HEADER_HEIGHT + FOOTER_HEIGHT;

  useEffect(() => {
    // Restore scroll position if available
    const currentScrollY = scrollPositions[pathname];
    if (currentScrollY !== undefined) {
      window.scrollTo(0, currentScrollY + OFFSET);
    } else {
      // If no stored position, scroll to top
      window.scrollTo(0, 0);
    }

    // Save the scroll position when the component unmounts or pathname changes
    const saveScrollPosition = () => {
      setScrollPositions((prevPositions) => ({
        ...prevPositions,
        [pathname]: window.scrollY - OFFSET, // Save adjusted scroll position
      }));
    };

    // Event listener to save scroll position on unload
    window.addEventListener("beforeunload", saveScrollPosition);

    return () => {
      saveScrollPosition(); // Save position on unmount or path change
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, [pathname]); // Depend only on pathname

  return null;
};

export default ScrollTop;


import { Box, Typography } from "@mui/material";
import React, { memo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMG_URL } from "../../../constants/ApiConstants";
import ReactLoading from "react-loading";
import "./Jewelry.scss";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
export interface jewelryDataType {
  id: string;
  createdAt: null | string;
  updatedAt: null | string;
  name: string;
  image: string;
}


interface jewelryProps {
    data: jewelryDataType[];
    onClose: () => void;
}

const CustomImgLoader = () => {
  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type="bubbles" color="#FFFF" height={100} width={50} />
      </Box>
    </>
  );
};

const FetchImages =(({ data }: any) => {
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <>
       {data && imageLoading && (
        <CustomImgLoader />
      )}
      <img
        src={`${IMG_URL}${encodeURIComponent(data.image.replace(/\\/g, "/"))}`}
        alt="catalog-img"
        className="innerImg"
        onLoad={() => setImageLoading(false)}
      />
      <Typography className="nameOverly" variant="subtitle1">
        {data.name}
      </Typography>
    </>
  );
});


interface CategoryItem {
  id: string; // or number, based on your data structure
  // Add any other properties you need from the item
}

interface CategoriesDrawerProps {
  data: CategoryItem[];
  onClose: () => void;
}


// export default function JewelryCardsContainer({data,onClose}: jewelryProps) {
//   const navigate = useNavigate();
//   //scrolling btns

//   const scrollRef = useRef<HTMLDivElement>(null);

//   const scroll = (direction: 'left' | 'right') => {
//     if (scrollRef.current) {
//       const scrollAmount = direction === 'right' ? 200 : -200; // Adjust scroll amount as needed
//       scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
//     }
//   };
  

//   return (
//     <div>
//       <Box className="categories_drawer" ref={scrollRef} position="relative">
//       <button onClick={() => scroll('left')} className="scroll-button left">
//         &lt;
//       </button>
//         {data.map((item: any) => (
//           <Box
//             className="categories_drawer_img"
//             key={item.id}
//             // onClick={() => navigate(`/jewelry/${item.id}`)}
//             onClick={() => {
//               navigate(`/jewelry/${item.id}`);
//               onClose(); // Call onClose when an item is clicked
//           }}
//           >
//             <FetchImages data={item} />
//           </Box>
//         ))}
//         <button onClick={() => scroll('right')} className="scroll-button right">
//         &gt;
//       </button>
//       </Box>
//     </div>
//   );
// }

interface JewelryProps {
  data: { id: string; [key: string]: any }[]; // Adjust this type based on your data structure
  onClose: () => void;
}


export default function JewelryCardsContainer({data,onClose}: jewelryProps) {
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'right' ? 200 : -200; // Adjust scroll amount as needed
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Box className="categories_drawer" ref={scrollRef} position="relative">
        <button onClick={() => scroll('left')} className="scroll-button left">
        <KeyboardArrowLeftIcon/>
        </button>
        {data.map((item) => (
          <Box
            className="categories_drawer_img"
            key={item.id}
            onClick={() => {
              navigate(`/jewelry/${item.id}`);
              onClose(); // Call onClose when an item is clicked
            }}
          >
            <FetchImages data={item} />
          </Box>
        ))}
        <button onClick={() => scroll('right')} className="scroll-button right">
        <KeyboardArrowRightIcon/>
        </button>
      </Box>
    </div>
  );
};



//Dev
// export const appShortName = "divine";
// export const API_URL = "http://192.168.1.10:8080/api/v1/";
// // export const API_URL = "https://divineempyrean.com/api/v1/";//live
// // export const API_URL = 'http://localhost:8080/api/v1/';
// export const IMG_URL = API_URL + "images?url=";
// export const BASE_URL = "http://localhost:3000";
// export const PAYPAL_CLIENT_ID = "AfzV9rQ8PP7ckKRNkINa37KDLMz7vlTmlQB4mm-HiSkh_FOhPC3YjOcj923kTUX-vrOafV5kf_uPu70r";
// export const PAYPAL_SECRET_KEY = "EEN4rortQ-VmAEKT7xc0vTM6rFIrqL2e22wrP0tseF-rBDobi7NrySbcRbMVeNFYE1xsj1bpKnQBozYf";
// export const RAZORPAY_CLIENT_ID = "rzp_test_V49xDxSLeFHgza";
// export const RAZORPAY_SECRET_KEY = "g4vQ9dsPDWo7oFWQQ75HmNEl";
// export const captchaSiteKey = '6LcSkEIiAAAAAFG_iGFzQD8MOa2S9sYmdAQ-JoRR';



//Prod
export const appShortName = "divine";
export const API_URL = "https://divineempyrean.com/api/v1/";//live
export const IMG_URL = API_URL + "images?url=";
export const BASE_URL = "https://divineempyrean.com";
export const PAY_PAL_CLIENT_ID = "AVG7Yj_Mi3j_fNeSAUBFVCsphc-3kzfk4eYOR_-JcJBiaKea20XqwsQwfAe8IIr45rgxxgT86WZfkUNc";
export const PAY_PAL_SECRET_KEY = "ENToy-_Mbyhi4HfS7vl4J9C0NdXkJIXZL2zueo6NCRJza2_PYx3Ej7nyBEs-XQ3c9z0iuFoo88_Zk65J";
export const RAZORPAY_CLIENT_ID = "rzp_live_wTDBf3WYckgKTw";
export const RAZORPAY_SECRET_KEY = "kdGNY0o2rwJjbAQ8SWwCuPvm";
export const captchaSiteKey = '6LcSkEIiAAAAAFG_iGFzQD8MOa2S9sYmdAQ-JoRR';






// export const STRIPE_PAYMENT_GATEWAY_SECRET_KEY =
//   "sk_test_51OuRQBSAVbRdSiFuSHGGpLM2lobYEiq0Vo8dRRRo1BgD2AbM06Caqb4TGz12QKSNhPx2th1FmyUCZjph4F4h2vvU00My8ZU1aM";
// export const STRIPE_PAYMENT_GATEWAY_PUBLIC_KEY =
//   "pk_test_51OyROySALK1bDY3SDxfTlXwbirEEkVQtW5kAVEpQLXc1hkwMnEYIhS9K3ReZel76oPcUA60CbZWnet9XF2UWsvqV001QLUgPEn";